

















































































































import 'reflect-metadata'
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import DcommAsset from '@/js/DcommAsset'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import Spinner from '@/components/misc/Spinner.vue'
import NftCol from './NftCol.vue'
import Tooltip from '@/components/misc/Tooltip.vue'

import Big from 'big.js'
import { BN } from '@dcomm-tech/dcomm-js/dist'
import { ONEDCM } from '@dcomm-tech/dcomm-js/dist/utils'
import { bnToBig } from '@/helpers/helper'
import { priceDict } from '@/store/types'
import { WalletType } from '@/js/wallets/types'
import UtxosBreakdownModal from '@/components/modals/UtxosBreakdown/UtxosBreakdownModal.vue'

@Component({
    components: {
        UtxosBreakdownModal,
        Spinner,
        NftCol,
        Tooltip,
    },
    filters: {
        cleanDcmBN(val: BN) {
            let big = Big(val.toString()).div(Big(ONEDCM.toString()))
            return big.toLocaleString()
        },
    },
})
export default class BalanceCard extends Vue {
    isBreakdown = true

    $refs!: {
        utxos_modal: UtxosBreakdownModal
    }

    updateBalance(): void {
        this.$store.dispatch('Assets/updateUTXOs')
        this.$store.dispatch('History/updateTransactionHistory')
    }

    showUTXOsModal() {
        this.$refs.utxos_modal.open()
    }
    get dcomm_asset(): DcommAsset | null {
        let dcomm = this.$store.getters['Assets/AssetDCOMM']
        return dcomm
    }

    toggleBreakdown() {
        this.isBreakdown = !this.isBreakdown
    }

    get dvmUnlocked(): BN {
        if (!this.dcomm_asset) return new BN(0)
        return this.dcomm_asset.amount
    }

    get dvmLocked(): BN {
        if (!this.dcomm_asset) return new BN(0)
        return this.dcomm_asset.amountLocked
    }

    get evmUnlocked(): BN {
        // ACT chain Balance
        if (!this.wallet) return new BN(0)
        // convert from ^18 to ^9
        let bal = this.wallet.ethBalance
        return bal.div(new BN(Math.pow(10, 9).toString()))
    }

    get totalBalance(): BN {
        if (!this.dcomm_asset) return new BN(0)

        let tot = this.dcomm_asset.getTotalAmount()
        // add EVM balance (ACT + AST)
        tot = tot.add(this.evmUnlocked)
        return tot
    }

    get totalBalanceBig(): Big {
        if (this.dcomm_asset) {
            let denom = this.dcomm_asset.denomination
            let bigTot = bnToBig(this.totalBalance, denom)
            return bigTot
        }
        return Big(0)
    }

    get dcmPriceText() {
        return this.priceDict.usd
    }

    get totalBalanceUSD(): Big {
        let usdPrice = this.priceDict.usd
        let usdBig = this.totalBalanceBig.times(Big(usdPrice))
        return usdBig
    }

    get totalBalanceUSDText(): string {
        if (this.isUpdateBalance) return '--'
        return this.totalBalanceUSD.toLocaleString(2)
    }
    // should be unlocked (AST+ATH), locked (AST+ATH) and staked and lockedStakeable
    get balanceText(): string {
        if (this.dcomm_asset !== null) {
            let denom = this.dcomm_asset.denomination
            return this.totalBalanceBig.toLocaleString(denom)
        } else {
            return '?'
        }
    }

    get balanceTextLeft(): string {
        if (this.isUpdateBalance) return '--'
        let text = this.balanceText
        if (text.includes('.')) {
            let left = text.split('.')[0]
            return left
        }
        return text
    }

    get balanceTextRight(): string {
        if (this.isUpdateBalance) return ''
        let text = this.balanceText
        if (text.includes('.')) {
            let right = text.split('.')[1]
            return right
        }
        return ''
    }

    // Locked balance is the sum of locked DCM tokens on AST and ATH chain
    get balanceTextLocked(): string {
        if (this.isUpdateBalance) return '--'

        if (this.dcomm_asset !== null) {
            let denom = this.dcomm_asset.denomination
            let tot = this.authorityLocked.add(this.authorityLockedStakeable)
            // let otherLockedAmt = this.authorityLocked.add(this.authorityLockedStakeable)
            let pLocked = Big(tot.toString()).div(Math.pow(10, denom))
            let amt = this.dcomm_asset.getAmount(true)
            amt = amt.add(pLocked)

            return amt.toLocaleString(denom)
        } else {
            return '--'
        }
    }

    get balanceTextMultisig() {
        if (this.isUpdateBalance) return '--'

        if (this.dcomm_asset !== null) {
            let denom = this.dcomm_asset.denomination
            return bnToBig(this.dvmMultisig.add(this.authorityMultisig), denom).toLocaleString()
        } else {
            return '--'
        }
    }

    get dvmMultisig(): BN {
        if (this.dcomm_asset !== null) {
            return this.dcomm_asset.amountMultisig
        } else {
            return new BN(0)
        }
    }

    get authorityBalance() {
        return this.$store.getters['Assets/walletAuthorityBalance']
    }

    get authorityUnlocked(): BN {
        return this.authorityBalance.available
    }

    get authorityMultisig(): BN {
        return this.authorityBalance.multisig
    }

    get authorityLocked(): BN {
        return this.authorityBalance.locked
    }

    get authorityLockedStakeable(): BN {
        return this.authorityBalance.lockedStakeable
    }

    get unlockedText() {
        if (this.isUpdateBalance) return '--'

        if (this.dcomm_asset) {
            let xUnlocked = this.dcomm_asset.amount
            let pUnlocked = this.authorityUnlocked

            let denom = this.dcomm_asset.denomination

            let tot = xUnlocked.add(pUnlocked).add(this.evmUnlocked)

            let amtBig = bnToBig(tot, denom)

            return amtBig.toLocaleString(denom)
        } else {
            return '--'
        }
    }

    get pBalanceText() {
        if (!this.dcomm_asset) return '--'
        if (this.isUpdateBalance) return '--'

        let denom = this.dcomm_asset.denomination
        let bal = this.authorityUnlocked
        let bigBal = Big(bal.toString())
        bigBal = bigBal.div(Math.pow(10, denom))

        if (bigBal.lt(Big('1'))) {
            return bigBal.toLocaleString(9)
        } else {
            return bigBal.toLocaleString(3)
        }
    }

    get stakingAmount(): BN {
        return this.$store.getters['Assets/walletStakingBalance']
    }

    get stakingText() {
        let balance = this.stakingAmount
        if (!balance) return '0'
        if (this.isUpdateBalance) return '--'

        let denom = 9
        let bigBal = Big(balance.toString())
        bigBal = bigBal.div(Math.pow(10, denom))

        if (bigBal.lt(Big('1'))) {
            return bigBal.toString()
        } else {
            return bigBal.toLocaleString()
        }
    }

    get wallet(): WalletType | null {
        return this.$store.state.activeWallet
    }

    get isUpdateBalance(): boolean {
        if (!this.wallet) return true
        return this.wallet.isFetchUtxos
    }

    get priceDict(): priceDict {
        return this.$store.state.prices
    }

    get hasLocked(): boolean {
        return (
            !this.dvmLocked.isZero() ||
            !this.authorityLocked.isZero() ||
            !this.authorityLockedStakeable.isZero()
        )
    }
    get hasMultisig(): boolean {
        return !this.dvmMultisig.isZero() || !this.authorityMultisig.isZero()
    }
}
